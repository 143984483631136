<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reporte')+': '+$t('Pagos')}} </h3>
		</div>
		<div class="p-col-12">
			<div class="card">
                <DataTable :value="facturas" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm"
					:paginator="true" :rows="25" >
                    <template #header>
                        <div class="table-header">
							<div>
								<Dropdown class="p-mr-2" v-model="year" :options="$store.state.years" optionLabel="nombre"></Dropdown>
								<Dropdown class="p-mr-2" v-model="mes" :options="$store.state.meses" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
								<Dropdown class="p-mr-2" v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
								<Button :label="$t('Buscar')" style="width: 110px;" icon="pi pi-search" class="p-button-primary p-mr-2" @click="Buscar()"/>
							</div>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
					<Column field="id" :header="$t('Estatus')" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
							<Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff" v-if="slotProps.data.pagada"/>
							<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff" v-else/>
							<strong v-if="!slotProps.data.pagada && i18n.locale() == 'es'">{{opciones[1].nombre}}</strong>
							<strong v-if="!slotProps.data.pagada && i18n.locale() == 'en'">{{opciones[1].name}}</strong>
							<strong v-if="slotProps.data.pagada && i18n.locale() == 'es'">{{opciones[2].nombre}}</strong>
							<strong v-if="slotProps.data.pagada && i18n.locale() == 'en'">{{opciones[2].name}}</strong>
						</template>
					</Column>
                    <Column field="id" :header="$t('Nº')" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.id}}                              
                        </template>
                    </Column>
                    <Column field="fechad" :header="$t('Fecha')" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.fechad}}                              
                        </template>
                    </Column>
                    <Column field="cliente.codigo" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
							<div v-if="slotProps.data.renovacion>0">
                                {{slotProps.data.cliente.codigo2+slotProps.data.renovacion}}    
                            </div>
                            <div v-else>
                                {{slotProps.data.cliente.codigo}}   
                            </div>                         
                        </template>
                    </Column>
                    <Column field="cliente.cliente" :header="$t('Cliente')+' #'" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.cliente.cliente}}                              
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Nombre Completo')" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}                              
                        </template>
                    </Column>
                    <Column :header="$t('Monto')" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.monto<0" style="color:red;">
                                {{moneda(slotProps.data.monto)}}
                            </div>
                            <div v-else>
                                {{moneda(slotProps.data.monto)}}
                            </div>                         
                        </template>
                    </Column>
                    <Column :header="$t('Pagos')" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.recibido<0" style="color:red;">
                                {{moneda(slotProps.data.recibido)}}  
                            </div>
                            <div v-else>
                                {{moneda(slotProps.data.recibido)}}  
                            </div>                            
                        </template>
                    </Column>
                    <Column :header="$t('Deudas')" :sortable="false" headerStyle="width: 120px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.pendiente<0" style="color:red;">
                                {{moneda(slotProps.data.pendiente)}}
                            </div>
                            <div v-else>
                                {{moneda(slotProps.data.pendiente)}}
                            </div>
                        </template>
                    </Column>
                    <Column :header="$t('Ver')" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(slotProps.data)" v-if="slotProps.data.pagada"/>
                        </template>
                    </Column>
                    <template #footer footerStyle="text-align:right">
                        <Button icon="pi pi-download" class="p-button-rounded p-button-warning p-mr-2" @click="VerXLS()" v-if="facturas.length>0"/>
                    </template>
                </DataTable>
			</div>
        </div>
	</div>
	

</template>

<script>
import API from "../service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import PDF from "../service/PDF";
import XLS from "../service/XLS";

export default {
	components: {
    },
	data() {
		return {
			insert: false,
			edit: false,
			del: false,
			i18n: null,
            caso: null,
            facturas: [],
			selectedCustomers: null,
			filters: {},
			url: null,
			opcion: {code: 1, nombre: 'Pendiente', name: 'Pending'},
			opciones: [
				{code: 0, nombre: 'Todos', name: 'All'},
				{code: 1, nombre: 'Pendiente', name: 'Pending'},
				{code: 2, nombre: 'Aprobado', name: 'Approved'}
			],
			year: null,
			mes: null,
		}
	},
	productService: null,
	eventService: null,
	created() {
		this.year = this.$store.state.year;
		this.mes = this.$store.state.mes;
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
        Mostrar(){
			this.$store.commit('Loading');
			this.totaldeudas = 0;
            const Consulta = new API('Reportes');
            Consulta.Ini('Report5').then(result => {
                //this.$store.state.error = result;	
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
                this.$store.commit('Loading');
            }); 
        },
		VerXLS(){
            const xls = new XLS();
            xls.Reporte5(this.facturas,this.opciones);
		},
        Buscar(){
			if(this.mes && this.year && this.opcion){
				this.$store.commit('Loading');
				this.facturas = [];
				const Consulta = new API('Reportes');
				Consulta.Procesar('Report5',{
					caso: 	'Buscar',
					opcion: this.opcion.code,
					mes: this.mes.code,
					year: this.year.code,
					}).then(response => {
						//this.$store.state.error = response.result.facturas[0];
						this.facturas = response.result.facturas;
						this.$store.commit('Loading');
					});
			}
        },
        Ver(data){
            const doc = new PDF();
            doc.Factura(data);
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
		toggle1(event) {
			this.$refs.op1.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		toggle3(event) {
			this.$refs.op3.toggle(event);
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},
	},
	computed: {

    }

}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
